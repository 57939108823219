const TAG = "LanguageManager";
class LanguageManager {
    fallback_language = "sv"
    currentLangCode = "sv";
    languages = [];

    _debug = false;

    constructor() {
        console.log(TAG, "Creating a LanguageManager Singleton!", {fallBack: this.fallback_language, langCode: this.currentLangCode, langs: this.languages});
    }

    setFallbackLanguage(fallback_language: string | undefined = undefined) {
        if(this._debug) console.log(TAG, "Setting Fallback Language");
        this.fallback_language = fallback_language || this.fallback_language;
    }
    setCurrentLangCode(langCode: string | undefined = undefined) {
        if(this._debug) console.log(TAG, "Setting current Language");
        this.currentLangCode = langCode || this.currentLangCode;
    }
    setLanguages(languages = []){
        if(this._debug) console.log(TAG, "Setting list of available languages");
        this.languages = languages;
    }

    getLocalizedItem(text, fallbackToDefault= false) {
        if (!text) { return ""; }
        const clc = this.currentLangCode;
        if (text[clc]) {
            if(this._debug) console.log(TAG, "Text exists in CLC: ", text[clc]);
            return text[clc];
        } else if(text['x']) {
            return text['x'];
        }
        else if (fallbackToDefault && text[this.fallback_language]) { return text[this.fallback_language]; }
        else { return ""; }
    }
}
const languageManager = new LanguageManager();
export default languageManager;
