import React, { Component } from "react";
import './Events.scss';
import {FormattedMessage, IntlShape, injectIntl, defineMessages } from 'react-intl';

/*const messages = defineMessages({
    bye: {
        id: 'otherObject-bye',
        defaultMessage: 'Bye bye!',
    },
});*/

class Events extends Component {


    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //ReactGA.pageview('Events');
        /* var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']); */
    }

    render() {
        //console.log(this.props);

        return (
            <div className={"Events"}>
                Events
            </div>
        );
        //
    }
}

export default Events; //injectIntl()
