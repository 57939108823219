import React, { Component } from "react";
import './Categories.scss';
import Titlebar from '../../components/Titlebar/Titlebar';
import ListItem from '../../components/ListItem/ListItem';
import SwapButton from '../../components/SwapButton/SwapButton';
import LanguageManager from "../../providers/data-manager/language-manager";
import {Util} from "../../providers/Utils";
import languageManager from "../../providers/data-manager/language-manager";

class Categories extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: 0
        }
    }

    componentDidMount() {
        //console.log("AppList Did Mount!");
        //ReactGA.pageview('Categories');
        /* var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']); */
    }

    setTab(i) {
        this.setState({tab: i},null);
    }

    getCategory(params, container) {
        console.log("Getting Category from: ", container, params);
        const nextParam = parseInt(params[0]);
        if(container.Categories && container.Categories.length > 0 && Array.isArray(params) && params.length > 0) {
            for(let c in container.Categories) {
                console.log("C: ", container.Categories[c].Id, nextParam);
                if(container.Categories.hasOwnProperty(c) && container.Categories[c].Id === nextParam) {
                    console.log("This Category: ", container.Categories[c], "Looks like it matches param 0: ", params[0]);
                    params.shift();
                    console.log("New Params: ", params);
                    return this.getCategory(params, container.Categories[c]);
                }
            }
        } else {
            console.log("We have reached our destination...?");
            return container;
        }
    }

    printCategories(container) {
        let categories = [];

        for(let c in container.Categories) {
            if(container.Categories.hasOwnProperty(c)) {
                const category = container.Categories[c];
                const name = LanguageManager.getLocalizedItem(category.Name);
                categories.push(<ListItem
                    key={"category_"+name}
                    image={Util.getMediaUrl(category.ListImage)}
                    title={name}
                    subtitle={""}
                    navlink={category.Id+"/"}
                />)
            }
        }
        return categories;
    }

    printGuides(category) {
        let walks = [];
        for(let wid in category.WalkIds) {
            console.log("Walk to add: ", category.WalkIds[wid]);
            for(let w in this.props.data.umbraco.Walks) {
                if(this.props.data.umbraco.Walks.hasOwnProperty(w) && this.props.data.umbraco.Walks[w].Id === category.WalkIds[wid]) {
                    console.log("Walk matched");
                    const walk = this.props.data.umbraco.Walks[w];
                    walks.push(<ListItem
                        key={"li_"+w}
                        image={walk.DescriptionImages.length > 0 ? Util.getMediaUrl(walk.DescriptionImages[0]) : ""}
                        title={languageManager.getLocalizedItem(walk.Name)}
                        subtitle={walk.Locations.length + " LOCATIONS"}
                        navlink={"/"+this.props.routeData.params.appid+"/"+this.props.routeData.params.language+"/"+walk.Id+"/"}
                    />)
                    break;
                }
            }
        }
        return walks;
    }

    render() {
        console.log("Categories Render");
        let elements = [];
        if(this.props.data.umbraco) {
            let container = this.props.data.umbraco;
            if(this.props.routeData && this.props.routeData.params && this.props.routeData.params[0]) {
                console.log("Specific Container to find");
                const params = this.props.routeData.params[0].split("/");
                params.pop();
                console.log({params});
                container = this.getCategory(params, container);
            }
            console.log("Container: ", container);
            switch(container.Type) {
                case "guideCategory":
                    elements = this.printGuides(container);
                    break;
                case "guideCategory1":
                    console.log("Loop Categories in container: ", container);
                    elements = this.printCategories(container);
                    break;
                default:
                    console.log("Default with no type, assume App-container");
                    elements = this.printCategories(container);
            }

        }
        //console.log("APPSLENGTH:",apps.length);
        //console.log("AppList: ", this.props.webappData);
        return (
            <div className={"Categories List"}>
                <Titlebar
                    title={"Categories"}
                    setTab={this.setTab.bind(this)}
                    tabs={["Categories","Info"]}
                    tab={this.state.tab}
                    back={"../"}
                    webappData={this.props.webappData}
                    swapped={this.props.swapped}
                />
                <div className={"Listcontent"}>
                    <div className={"Tab"+(this.state.tab===0?" Active":"")}>
                        {elements}
                    </div>
                </div>
                <SwapButton text={"Map"} swap={this.props.swap}/>
            </div>
        );
    }
}

export default Categories;
