import React, {Component} from 'react';
import './Titlebar.scss';
import {IntlProvider} from "react-intl";
import {NavLink} from "react-router-dom";
import {ReactComponent as BackArrowIcon} from '../../assets/Icons/play4.svg';

class Titlebar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        //console.log("WebappConfig: ", this.props.webappData, this.props.webappData && this.props.webappData.WebAppConfig ? "url("+this.props.webappData.WebAppConfig.TitlebarBackground+")" : "Potatis");
        let tabs = [];
        for(let t = 0; t < this.props.tabs.length; t++) {
                tabs.push(<div key={"tab_"+t} className={"TabBtn"+(this.props.tab===t?" Active":"")} onClick={function(t){this.props.setTab(t)}.bind(this,t)}>{this.props.tabs[t]}</div>);
        }
        let style = {};
        if(this.props.webappData && this.props.webappData.WebAppConfig && this.props.webappData.WebAppConfig.TitlebarBackground) {
            style.backgroundImage = "url(" + this.props.webappData.WebAppConfig.TitlebarBackground + ")";
        }
        if(this.props.swapped) {
            //style.display = "none";
        }
        return (
        <div className={"Titlebar"} style={style}>
            <div className={"BackBtn"}>{this.props.back ? <NavLink to={this.props.back}><BackArrowIcon className={"backarrow"}/>Back</NavLink> : ""}</div>
            <div className={"Title"}>{this.props.title}</div>
            <div className={"Tabs"}>{tabs}</div>
        </div>
        );
    }
}


export default Titlebar;
