import React, {Component} from 'react';
import { renderRoutes } from "react-router-config";
import Header from '../components/header/Header';
//import Footer from '../components/Footer/Footer';
import LanguageSelect from '../pages/LanguageSelect/LanguageSelect';
import {matchPath, Switch} from "react-router";
import Routes from "./routes";
import {loadData} from "../providers/data-manager/loadData";
import './Root.scss';
import SearchResult from "../pages/SearchResult/SearchResult";
import languageManager from "../providers/data-manager/language-manager";
import {Util} from "../providers/Utils";
import SwapButton from "../components/SwapButton/SwapButton";

let Map;
let _paq;

const TAG = "Root";
class Root extends Component {

    constructor(props) {
        super(props);
        //console.log("ROOT CONSTRUCTOR");
        //console.log(props);
        let logoWide = "/OSMS_logga_utanmost.png";
        let logoSmol = "/OSMS_logga2.png";
        if (props.webappData && props.webappData.WebAppConfig) {
            let wac = props.webappData.WebAppConfig;
            if (wac.HeaderIcon) {
                logoWide = wac.HeaderIcon;
            }
            if(wac.HeaderIconMobile) {
                logoSmol = wac.HeaderIconMobile;
            } else if (!wac.HeaderIconMobile && wac.HeaderIcon) {
                logoSmol = wac.HeaderIcon;
            }
        }
        this.state = {
            data: props.data,
            webappData: props.webappData,
            mobile: true,
            openLanguageSelect: false,
            showMap: false,
            params: {},
            swapScreen: props.data.firebase.APP_SETTINGS.Webapp_Swapped,
            moveLeft: false,
            logoWide: logoWide,
            logoSmol: logoSmol,
            searchResult: [],
            showSearchResult: false,
            overSearch: false,
            innerHeight: null,
            languages: [],
            walk: null,
            languageManager: languageManager
        }
        //console.log("WEBAPPDATA:",this.state.webappData);


        console.log(TAG, "Serverside Rendered: ", props.data.ssr);
        console.log(TAG, "swapScreen: ", this.state.swapScreen);
        this.routeData = this.getCurrentRoute();
    }


    componentDidMount() {
        //console.log("Root did mount");
        let MapThing = require('../pages/Map/MapThing');
        //console.log(MapThing);
        Map = MapThing.Map;
        //console.log(Map);
        //let data = this.state.data;
        //data.ssr = false;
        this.setState({ showMap: true})
        if(!this.props.data.ssr) {
            window.onresize = function() {
                //console.log("Resized! ", window.innerHeight);
                window.setTimeout(this.setState.bind(this, {innerHeight: window.innerHeight}), 200);
            }.bind(this)
            //this.state.innerHeight = window.innerHeight;
            let newState = {innerHeight: window.innerHeight};
            const routeData = this.getCurrentRoute();
            let supported;
            try {
                supported = JSON.parse(this.state.data.firebase.APP_LANGUAGE.supported);
            } catch {
                supported = this.state.data.firebase.APP_LANGUAGE.supported;
            }
            if(!routeData.params.language && routeData.params.appid && supported.length > 1) {
                newState.openLanguageSelect = true;
            }
            this.setState(newState, () => {
                this.makeLanguageList();
            });
            //console.log("Setting innerHeight to window",  window.innerHeight);
        }
        //this.swap()
    }

    componentDidUpdate(prevProps, prevState) {
        const newRouteData = this.getCurrentRoute();
        if(newRouteData) {
            console.log(TAG, "Updated", newRouteData.params.appid, newRouteData.params.language, newRouteData.params.walkid, newRouteData.params.location, this.state.languages)
            if(window._paq) {
                if(
                    newRouteData.params.appid != this.routeData.params.appid ||
                    newRouteData.params.language != this.routeData.params.language ||
                    newRouteData.params.walkid != this.routeData.params.walkid ||
                    newRouteData.params.location != this.routeData.params.location
                ) {
                    //console.log(TAG, "Push to Matomo", window.location.pathname);
                    window._paq.push(['setCustomUrl', '/' + window.location.pathname]);
                    console.log(TAG, "Tack PageView: ", window._paq.push(['trackPageView']), window.location.pathname);
                }
            }
            //_paq.push(['setDocumentTitle', document.getElementsByTagName("title")[0].innerHTML]);
            let supported;
            try {
                supported = JSON.parse(this.state.data.firebase.APP_LANGUAGE.supported);
            } catch {
                supported = this.state.data.firebase.APP_LANGUAGE.supported;
            }
            console.log(TAG, "Supported languages: ", supported, supported.length);
            if(newRouteData.params.appid && !newRouteData.params.language && !this.state.openLanguageSelect && supported.length > 1) {
                console.log(TAG, "More than one language supported");
                this.makeLanguageList().then(() => {
                    this.setState({openLanguageSelect: true},null);
                })
            } else if(newRouteData.params.appid && !newRouteData.params.language && !this.state.openLanguageSelect && supported.length === 1) {
                console.log("There is only a single language, auto push");
                this.props.history.push(supported[0]+'/');

            } else if (!newRouteData.params.appid && this.state.openLanguageSelect){
                this.setState({openLanguageSelect: false},null);
            }
            if(newRouteData.params && newRouteData.params.language && languageManager.currentLangCode !== newRouteData.params.language) {
                this.setState({languages: this.state.languages})
                languageManager.setCurrentLangCode(newRouteData.params.language);
            }
        }
        this.routeData = newRouteData;
    }

    toggleLanguageSelect() {
        this.setState({openLanguageSelect: !this.state.openLanguageSelect},null);
    }

    setActiveMarker(i) {
        this.setState({activeMarker: i},null);
    }

    getCurrentRoute() {
        if(this.state.data.ssr) { return; }
        const currentRoute = Routes[0].routes.find(route => matchPath(window.location.pathname, route)) || {};
        const routeData = matchPath(window.location.pathname, currentRoute);
        //this.setState({params: routeData.params},null);
        return routeData;
    }

    downloadUmbracoData() {
        console.log("We should rly download Umbraco data...", window.location.pathname);
        //const currentRoute = Routes[0].routes.find(route => matchPath(window.location.pathname, route)) || {};
        //const routeData = matchPath(window.location.pathname, currentRoute);
        const routeData = this.getCurrentRoute();
        console.log(TAG, "currentRoute:",routeData);
        //console.log(this.props.match);
        return loadData(routeData.params.appid, routeData.params.walkid, this.state.data).then(data => {
            this.setState({
                data
            }, () => {/*this.setActiveWalk(routeData)*/});
            return data;
        });
    }

    swap = () => {
        this.setState({swapScreen: !this.state.swapScreen},null);
    }

    moveLeft(moveLeft) {
        /* This function only exists to move the Leaflet copyright box to the left when LeftList is opened */
        this.setState({moveLeft: moveLeft},null);
    }
    setSearchResult(result) {
        this.setState({searchResult: result ? result : []}) //, showSearchResult: (result && result.length > 0)
        console.log("Setting results: ", result, (result && result.length > 0));
    }
    showSearchResult(value) {
        this.setState({showSearchResult: (value && this.state.searchResult.length > 0)})
    }
    setOverSearch(value) {
        this.setState({overSearch: value});
    }
    toggleSearchResult() {
        //console.log("Toggle Search Result", this.state.overSearch, this.state.showSearchResult);
        if(!this.state.overSearch && this.state.showSearchResult && !this.state.mobile) {
            this.setState({showSearchResult:  false})
        }
        else if(this.state.overSearch && !this.state.showSearchResult && this.state.searchResult.length > 0) {
            this.setState({showSearchResult:  true})
        }
        else if(this.state.overSearch && this.state.mobile && !this.state.showSearchResult) {
            this.setState({showSearchResult:  true})
        }
        else if(this.state.overSearch && this.state.mobile && this.state.showSearchResult) {
            this.setState({showSearchResult:  false})
        }
    }

    makeLanguageList() {
        return new Promise((resolve, reject) => {
            console.log(TAG, "Making Language List");
            let languages = [];
            let default_lang = "";

            //let routeData = this.getCurrentRoute();

            //console.log(TAG, !!this.state.data,!!this.state.data.umbraco)
            // console.log(TAG, "Umbraco Data: ", this.state.data.umbraco);
            if(this.state.data && this.state.data.firebase) {
                const firebase = this.state.data.firebase;
                const routeData = this.routeData;

                console.log(TAG, "FBC: ", firebase);
                try {
                    languages = JSON.parse(firebase.APP_LANGUAGE.supported)
                } catch (e) {
                    languages = firebase.APP_LANGUAGE.supported;
                }
                //languages.splice(languages.indexOf(fbc.APP_LANGUAGE.default), 1);
                default_lang = firebase.APP_LANGUAGE.default;
                /*for(let l in languageManager.languages) {
                    //console.log("WALK: ",w,this.props.data.umbraco.Walks[w].Id,this.props.match.params.walkid);
                    if(this.state.data.umbraco.Walks.hasOwnProperty(w) && this.state.data.umbraco.Walks[w].Id === parseInt(routeData.params.walkid)) {

                        // If a specific walk is selected in the URL, limit the language list to what this walk has
                        if(routeData.params.walkid && walk.Id === parseInt(routeData.params.walkid)) {
                            languages = walk.Languages;
                            default_lang = walk.DefaultLanguage;
                            break;
                        }

                        // Otherwise sum up all the languages all walks support. After a language is selected, the walks supporting that language will be shown
                        //console.log(TAG, "Concating in these languages: ", walk.Languages);
                        languages = [...new Set([...languages,...walk.Languages])]
                        //console.log(TAG, "New LanguageList: ", languages);

                        //console.log(languages,default_lang);
                    }
                }*/
                console.log(TAG, languages,default_lang);
                languageManager.setFallbackLanguage(default_lang);
                languageManager.setLanguages(languages);
                console.log(TAG, "Languages: ", languageManager.languages, "Default Lang: ", languageManager.fallback_language);
                this.setState({languages:languageManager.languages, default_lang: languageManager.fallback_language}, resolve);
            } else {
                console.error(TAG, "Could not make LanguageList, no Firebase data");
            }
        })
    }

    setActiveWalk(routeData) {
        console.log(TAG, "Setting Active Walk");
        return new Promise((resolve, reject) => {

            let walk;
            console.log(TAG, "Route Data: ", routeData);
            if(this.state.data && this.state.data.umbraco && routeData.params.walkid) {
                console.log(TAG, "Got WalkID? ", routeData.params.walkid);
                for (let w in this.state.data.umbraco.Walks) {
                    //console.log("WALK: ",w,this.props.data.umbraco.Walks[w].Id,this.props.match.params.walkid);
                    if (this.state.data.umbraco.Walks.hasOwnProperty(w) && this.state.data.umbraco.Walks[w].Id === parseInt(routeData.params.walkid)) {
                        walk = this.state.data.umbraco.Walks[w];
                        break;
                    }
                }
            }
            console.log(TAG, "Setting State");
            this.setState({walk}, resolve);
        })
    }

    getCurrentWalk(routeData) {
        console.log(TAG, "Get Current Walk", routeData);
        if(this.state.data && this.state.data.umbraco && routeData.params.walkid) {
            for(let w in this.state.data.umbraco.Walks) {
                //console.log("WALK: ",w,this.props.data.umbraco.Walks[w].Id,this.props.match.params.walkid);
                if(this.state.data.umbraco.Walks.hasOwnProperty(w) && this.state.data.umbraco.Walks[w].Id === parseInt(routeData.params.walkid)) {
                    const walk = this.state.data.umbraco.Walks[w];
                    if(!this.state.walk || this.state.walk.Id !== walk.Id) {
                        this.setState({walk});
                    }
                    return walk;
                }
            }
        }
        else {
            console.log(TAG, "No Umbraco data - No walk");
        }
        return null;
    }

    render() {
        //console.log(this.props);
        //console.log("Data: ", this.state.data);
        //console.log("Features: ", this.state.data.umbraco.Features);
        console.log("SWAPPED? ", this.state.swapScreen);
        let markers = [];
        let mapImage = null;
        let mapImageBounds = null;
        let firebase_app = null;
        let autoCenter = false;
        let centerPos = [];
        let initZoom = 0;
        //console.log(this.props.match);

        //let routeData = this.getCurrentRoute();
        const routeData = this.routeData;
        if(routeData) {
            console.log(TAG, "Routedata: ", routeData);
        }
        if(routeData && routeData.params && routeData.params.appid && this.state.data.firebase && this.state.data.firebase) {
            //console.log("Routedata in render: ", routeData);
            firebase_app = this.state.data.firebase;
            if(firebase_app) {
                //console.log("Firebase APP: ", firebase_app);
                //console.log(this.state.data.firebase[this.props.match.params.appid] ? this.props.data.firebase[this.props.match.params.appid] : "");


                /*firebase_app.APP_LANGUAGE.supported = (typeof firebase_app.APP_LANGUAGE.supported === "object" ? firebase_app.APP_LANGUAGE.supported : JSON.parse(firebase_app.APP_LANGUAGE.supported));
                /*if(this.state.data.firebase) {
                    for(let l in firebase_app.APP_LANGUAGE.supported) {
                        if(firebase_app.APP_LANGUAGE.supported.hasOwnProperty(l)) {
                            let lang = firebase_app.APP_LANGUAGE.supported[l];
                            //languages.push(<li key={"lang_"+l} style={{fontWeight: (firebase_app.APP_LANGUAGE.default === lang ? "bold": "normal")}}><NavLink to={(this.props.match.params.language ? "../"+lang+"/" : lang+"/")}>{lang}</NavLink></li>)
                            languages.push(lang);
                            if(firebase_app.APP_LANGUAGE.default === lang) {
                                default_lang = lang;
                            }
                        }
                    }
                }*/
            }

            // Get current walk


            /* Don't do anything for the map if this is serverside rendered, since the server can't run Leaflet anyways */
            if(!this.state.data.ssr && this.state.data.firebase.APP_SETTINGS.WEBAPP_Map) {
                markers = [];
                /* Prepare a list of mapmarkers */
                if(routeData.params.appid && !routeData.params.walkid) {
                    /* AppInstance
                    * Render all Walks on map */
                    if(this.state.data && this.state.data.umbraco) {
                        autoCenter = this.state.data.umbraco.AutoCenter;
                        centerPos = [this.state.data.umbraco.CenterLat, this.state.data.umbraco.CenterLon];
                        let walk;
                        const container = routeData.params.category ? this.props.data.umbraco.Categories.find(c => c.Id === parseInt(routeData.params.category,10)) : this.props.data.umbraco;
                        // console.log(TAG, "Container to grab data from: ", container);

                        const walkList = (container.Walks ? container.Walks : this.props.data.umbraco.Walks.filter((w) => {return container.WalkIds.indexOf(w.Id) > -1 && w.Languages.indexOf(languageManager.currentLangCode) > -1}))
                        // console.log(TAG, "WalkList to Render: ", walkList);
                        //const walkList = this.state.data.umbraco.Walks.filter(w => w.Languages.indexOf(languageManager.currentLangCode) > -1);
                        console.log(TAG, "WalkList to render mapmarkers for, mapped by language: ", walkList, languageManager.currentLangCode);
                        for(let w in walkList) {
                            if(walkList.hasOwnProperty(w)) {
                                walk = walkList[w];
                                //console.log("Potential Marker Walk: ", walk);
                                if(walk.CenterLat && walk.CenterLon) {
                                    let marker = {
                                        lat: walk.CenterLat,
                                        lng: walk.CenterLon,
                                        title: walk.Name,
                                        imgurl: walk.DescriptionImages.length > 0 ? walk.DescriptionImages[0] : "",
                                        id: walk.Id
                                    }
                                    //,link: (routeData.params.walkid ? "../"+walk.Id+"/" : walk.Id+"/")
                                    //console.log("Adding ",marker);
                                    markers.push(marker);
                                }
                            }
                        }
                        let d = this.state.data.umbraco;
                        if(d.MapImage && this.hasBounds(d)) {
                            console.log("This instance has a non-language Specific mapImage");
                            /* This instance has an non-language specific mapimage */
                            mapImage = d.MapImage;
                            mapImageBounds = [
                                [d.TopRightLat, d.TopRightLon],
                                [d.BottomLeftLat, d.BottomLeftLon]
                            ]
                        }
                    }
                } else if (routeData.params.appid && routeData.params.language && routeData.params.walkid) {
                    console.log(TAG, "We got appid, language and walkid, this is a active walk");
                    // Walk
                    /* Render all Locations on map */
                    const walk = this.getCurrentWalk(routeData);
                    if(walk) {
                        console.log(TAG, "Current Walk: ", walk);
                        autoCenter = walk.AutoCenter;
                        centerPos = [walk.CenterLat, walk.CenterLon];
                        initZoom = walk.InitZoom;
                        for(let l in walk.Locations) {
                            if(walk.Locations.hasOwnProperty(l)) {
                                let location = walk.Locations[l];
                                if(location.Latitude && location.Longitude) {

                                    let marker = {
                                        lat: location.Latitude,
                                        lng: location.Longitude,
                                        title: location.Name,
                                        imgurl: location.Images.length > 0 ? location.Images[0] : "",
                                        id: location.Id
                                    };
                                    //,link: (routeData.params.location ? "../"+location.Id+"/" : location.Id+"/")
                                    console.log("New marker: ",marker);
                                    markers.push(marker);
                                }
                            }
                        }
                        let d = walk;
                        //console.log("Walk MapImage: ", d.MapImage, this.hasBounds(d));
                        if(d.MapImage && this.hasBounds(d)) {

                            /* This Walk has an non-language specific mapimage */
                            mapImage = d.MapImage;
                            mapImageBounds = [
                                [d.TopRightLat, d.TopRightLon],
                                [d.BottomLeftLat, d.BottomLeftLon]
                            ]
                        }
                    }
                }
            }
        }

        //console.log("ServerLanguage: ",this.state.data.language);



        //let access_token, titleLayerUrl, mapBoxUser;
        let map_opt = {};
        if(this.state.data.firebase && this.state.data.firebase['APP_SETTINGS'] && this.state.data.firebase['APP_SETTINGS']['MAP_MapBoxAccessToken'] && this.state.data.firebase['APP_SETTINGS']['WEBAPP_Map']) {

            map_opt = {
                titleLayerUrl: "https://api.mapbox.com/styles/v1/{id}/" +
                    this.state.data.firebase['APP_SETTINGS']['MAP_MapCustomUrl'] +
                    "/tiles/256/{z}/{x}/{y}?access_token={access_token}",
                access_token: this.state.data.firebase['APP_SETTINGS']['MAP_MapBoxAccessToken'],
                mapBoxUser: this.state.data.firebase['APP_SETTINGS']['MAP_MapBoxUser']
            }

            //console.log("Default Access token: ", access_token);
            //console.log("Default URL: ", titleLayerUrl);
            //console.log("Default MapBoxUser: ", mapBoxUser);
            //console.log("Firebase App: ", firebase_app);
            if(firebase_app) {
                /*access_token = firebase_app['APP_SETTINGS']['MAP_MapBoxAccessToken'];
                titleLayerUrl =
                    "https://api.mapbox.com/styles/v1/{id}/" +
                    firebase_app['APP_SETTINGS']['MAP_MapCustomUrl'] +
                    "/tiles/256/{z}/{x}/{y}?access_token={access_token}";
                mapBoxUser = firebase_app['APP_SETTINGS']['MAP_MapBoxUser'];*/
                let app_map_opt = {}
                const app_settings = firebase_app['APP_SETTINGS'];
                if(typeof app_settings['MAP_MapCustomUrl'] !== 'undefined') app_map_opt.titleLayerUrl = "https://api.mapbox.com/styles/v1/{id}/" + firebase_app['APP_SETTINGS']['MAP_MapCustomUrl'] + "/tiles/256/{z}/{x}/{y}?access_token={access_token}";
                if(typeof app_settings['MAP_MapBoxAccessToken'] !== 'undefined') app_map_opt.access_token = firebase_app['APP_SETTINGS']['MAP_MapBoxAccessToken'];
                if(typeof app_settings['MAP_MapBoxUser'] !== 'undefined') app_map_opt.mapBoxUser = firebase_app['APP_SETTINGS']['MAP_MapBoxUser'];

                /*console.log("Specific app access token: ", app_map_opt.access_token);
                console.log("Specific app URL: ", app_map_opt.titleLayerUrl);
                console.log("Specific App MapBoxUser: ", app_map_opt.mapBoxUser);*/
                console.log(TAG, {app_map_opt});
                map_opt = Object.assign(map_opt, app_map_opt);
            }
            console.log(TAG, {map_opt});
        }
        //console.log("Specific App MapBoxUser: ", mapBoxUser);
        //console.log("SHOW SEARCH RESULT?", this.state.showSearchResult);
        //console.log("HEIGHT: ", this.state.innerHeight, "Setting: ", this.state.innerHeight+"px");
        //console.log("WebappData Root: ", this.state.webappData);

        const mapEnabled = this.state.data.firebase.APP_SETTINGS.WEBAPP_Map;
        if(!mapEnabled && this.state.swapScreen) {
            this.swap();
        }
        let appIdForSearch =
            routeData &&
            routeData.params.appid &&
            this.state.data.firebase &&
            this.state.data.firebase.APP_SETTINGS
                ? this.state.data.firebase.APP_SETTINGS.CMS_GUIDE_NUMBER : null;
        return (
            <div className={"Root"+ (mapEnabled ? "": " NoMap")} onClick={this.toggleSearchResult.bind(this)} style={this.state.innerHeight ? {height: this.state.innerHeight+"px"}:{}}>
                <Header
                    toggleLanguageSelect={this.toggleLanguageSelect.bind(this)}
                    routeData={routeData}
                    languages={this.state.languages}
                    logoWide={this.state.logoWide}
                    logoSmol={this.state.logoSmol}
                    webappData={this.state.webappData}
                    appId={appIdForSearch}
                    setSearchResult={this.setSearchResult.bind(this)}
                    showSearchResult={this.showSearchResult.bind(this)}
                    setOverSearch={this.setOverSearch.bind(this)}
                    features={this.state.data.umbraco && this.state.data.umbraco.Features ? this.state.data.umbraco.Features.Webapp : { Search: false}}
                />

                <div className={"Content"+(this.state.swapScreen ? " Swapped":"")}>
                    <SwapButton text={(this.state.swapScreen ? "Guides" : "Map")} swap={this.swap}/>
                    {this.state.showMap && mapEnabled ? (
                        <Map
                            markers={markers}
                            moveLeft={this.state.moveLeft}
                            activeMarker={routeData.params.location}
                            history={this.props.history}
                            routeData={routeData}
                            swapped={this.state.swapScreen}
                            mapImage={mapImage}
                            mapImageBounds={mapImageBounds}
                            autoCenter={autoCenter}
                            centerPos={centerPos}
                            initZoom={initZoom}
                            access_token={map_opt.access_token}
                            tileLayerUrl={map_opt.titleLayerUrl}
                            mapBoxUser={map_opt.mapBoxUser}
                        />
                    ) : <div className={"Map"}/>}

                    <Switch>
                        {renderRoutes(this.props.route.routes, {
                            data: this.state.data,
                            webappData: this.state.webappData,
                            downloadUmbracoData:this.downloadUmbracoData.bind(this),
                            toggleLanguageSelect:this.toggleLanguageSelect.bind(this),
                            makeLanguageList:this.makeLanguageList.bind(this),
                            swapped:this.state.swapScreen,
                            moveLeft:this.moveLeft.bind(this),
                            searchResult:this.state.searchResult,
                            showSearchResult: this.state.showSearchResult,
                            routeData:routeData
                        })}
                    </Switch>

                    {this.state.showSearchResult ? <SearchResult
                        routeData={routeData}
                        searchResult={this.state.searchResult}
                        params={routeData.params}
                        appId={appIdForSearch}
                        setOverSearch={this.setOverSearch.bind(this)}
                        setSearchResult={this.setSearchResult.bind(this)}
                        showSearchResult={this.showSearchResult.bind(this)}
                        mobile={this.state.mobile}
                    />:""}
                </div>

                {/* <Footer/> */}
                {(this.state.openLanguageSelect ? <LanguageSelect
                    appid={routeData.params.appid}
                    currentLang={routeData.params.language}
                    walkid={routeData.params.walkid}
                    location={routeData.params.location}
                    walk={this.state.walk}
                    /*languages={this.state.languages}
                    default_lang={this.state.default_lang}*/
                    toggleLanguageSelect={this.toggleLanguageSelect.bind(this)}
                /> : "")}
            </div>
        );
    }

    hasBounds(d) {
        return(d.TopRightLat && d.TopRightLon && d.BottomLeftLat && d.BottomLeftLon)
    }
}


export default Root;

/*const Root = ({ route }) => (
    <div>
        <h1>Root</h1>
        {renderRoutes(route.routes)}
    </div>
);
export default Root;*/
