import React, { Component } from "react";
import {Helmet} from "react-helmet";
import "./AppInstance.scss";
import Titlebar from '../../components/Titlebar/Titlebar';
import ListItem from '../../components/ListItem/ListItem';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import SwapButton from '../../components/SwapButton/SwapButton';
import * as util from "util";
import {Util} from "../../providers/Utils";
import languageManager from "../../providers/data-manager/language-manager";
import LanguageManager from "../../providers/data-manager/language-manager";

const TAG = "AppInstance";

class AppInstance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: 0
        }
    }

    componentDidMount() {
        //ReactGA.pageview('AppInstance');
        /* var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']); */

        //console.log("AppInstance did Mount");

        //this.props.setActive(this.props.match.params.appid, this.props.match.params.language, this.props.match.params.walkid, this.props.match.params.location);
        // console.log(TAG, "Do we have the data we need?");
        if(!this.props.data.umbraco) {
            // console.log(TAG, "Umbraco Data needed, download it now!");
            this.props.downloadUmbracoData();
        }
        else {
            // console.log(TAG, "Umbraco DATA:", this.props.data.umbraco.LanguageCode, this.props.match.params.language)
            if(this.props.data.umbraco.Id !== this.props.match.params.appid) {
                // Different app, download UmbracoData
                this.props.downloadUmbracoData();
            }
        }
    }
    componentDidUpdate(prevProps) {
        if(prevProps.match.params) {
            //console.log(prevProps.match.params, this.props.match.params);
            //this.props.setActive(this.props.match.params.appid, this.props.match.params.language, this.props.match.params.walkid, this.props.match.params.location);
            if(prevProps.match.params.language !== this.props.match.params.language) {
                // New language!
                /*if(this.props.data.umbraco && this.props.data.umbraco.LanguageCode !== this.props.match.params.language) {
                    //console.log("Different language of Umbraco Data needed, download it now!");
                    this.props.downloadUmbracoData();
                }*/ //We don't do this anymore with API3
            }
        }
    }

    setTab(i) {
        /*if(i === 2) {
            this.props.history.push("categories/");
        }*/
        this.setState({tab: i},null);
    }

    strip_html_tags(str)
    {
        if ((str===null) || (str==='') || typeof str == "undefined")
            return false;
        else
            str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    }

    openCategory = (category) => {
        console.log(TAG, "Opening Category: ", category);
        // this.setState({container: category});
    }

    render() {
        let languages = [];
        let default_lang;
        let walks = [];
        const serverLang = this.props.routeData ? this.props.routeData.params.language : this.props.data.language;
        let externalLinks = [];
        let categories = [];

        if(this.props.data && this.props.data.firebase) {
            let firebase_app = this.props.data.firebase;
            //console.log(this.props.data.firebase[this.props.match.params.appid] ? this.props.data.firebase[this.props.match.params.appid] : "");

            if(firebase_app) {
                firebase_app.APP_LANGUAGE.supported = (typeof firebase_app.APP_LANGUAGE.supported === "object" ? firebase_app.APP_LANGUAGE.supported : JSON.parse(firebase_app.APP_LANGUAGE.supported));
                if(this.props.data.firebase) {
                    for(let l in firebase_app.APP_LANGUAGE.supported) {
                        if(firebase_app.APP_LANGUAGE.supported.hasOwnProperty(l)) {
                            let lang = firebase_app.APP_LANGUAGE.supported[l];
                            //languages.push(<li key={"lang_"+l} style={{fontWeight: (firebase_app.APP_LANGUAGE.default === lang ? "bold": "normal")}}><NavLink to={(this.props.match.params.language ? "../"+lang+"/" : lang+"/")}>{lang}</NavLink></li>)
                            languages.push(lang);
                            if(firebase_app.APP_LANGUAGE.default === lang) {
                                default_lang = lang;
                            }
                        }
                    }
                }
            }


            if(this.props.data.umbraco) {
                const container = this.props.routeData && this.props.routeData.params.category ? this.props.data.umbraco.Categories.find(c => c.Id === parseInt(this.props.routeData.params.category,10)) : this.props.data.umbraco;
                // console.log(TAG, "Container to grab data from: ", container);

                const walkList = (container.Walks ? container.Walks : this.props.data.umbraco.Walks.filter((w) => { return container.WalkIds.indexOf(w.Id) > -1 && w.Languages.indexOf(languageManager.currentLangCode) > -1}))
               // console.log(TAG, "WalkList to Render: ", walkList);
                for(let w in walkList) {
                    if(walkList.hasOwnProperty(w)) {
                        let walk = walkList[w];
                        if(walk.Id === 47845) {
                            // console.log(TAG, "Walk: ", walk);
                        }
                        if(walk.Languages.includes(this.props.match.params.language)) { // Check if this walk has enabled the currently selected language in the URL
                            //walks.push(<li key={"walk_"+w} title={walk.Description}><NavLink to={walk.Id+"/"}>{walk.Name}</NavLink></li>)
                            const navLink = (this.props.routeData && this.props.routeData.params.category ? "../../" : "") + walk.Id+"/";
                            walks.push(<ListItem
                                key={"li_"+w}
                                image={walk.DescriptionImages.length > 0 ? Util.getMediaUrl(walk.DescriptionImages[0]) : ""}
                                title={languageManager.getLocalizedItem(walk.Name)}
                                subtitle={walk.Locations.length + " LOCATIONS"}
                                navlink={navLink}
                                numbered={false}
                            />)
                        }
                    }
                }


                const MenuItems = LanguageManager.getLocalizedItem(this.props.data.umbraco.MenuItems);
                if(MenuItems) {
                    // console.log(TAG, "MenuItems: ", MenuItems);
                    for(let l in MenuItems) {
                        // console.log(TAG, "MenuItem: ", l);
                        if(MenuItems.hasOwnProperty(l)) {
                            const link = MenuItems[l];

                            // console.log(TAG, "Adding external Link: ", link.Title, link.Path);
                            externalLinks.push(
                                <li key={"link_"+l}>
                                    <a target="_blank" href={link.Path}>{link.Title}</a>
                                </li>
                            )
                        }
                    }
                }

                for(let c in container.Categories) {
                    if(container.Categories.hasOwnProperty(c)) {
                        const category = container.Categories[c];

                        if(category.Type === "guideCategory") {
                            /* Webapp won't support the other category types, for now */

                            let walksWithLanguageExists = false;
                            for(let i in category.WalkIds) {
                                if(category.WalkIds.hasOwnProperty(i)) {
                                    const wid = category.WalkIds[i];
                                    // console.log(TAG, "WID: ", wid);
                                    const walk = this.props.data.umbraco.Walks.find((w) => w.Id === wid);
                                    // console.log(TAG, "Walk in Category: ", walk);

                                    if (walk && walk.Languages && walk.Languages.indexOf(languageManager.currentLangCode) > -1) {
                                        walksWithLanguageExists = true;
                                        break;
                                    }
                                }
                            }
                            // console.log(TAG, "Walks With Language Exists: ", walksWithLanguageExists);
                            if(walksWithLanguageExists) {
                                categories.push(<ListItem
                                    key={"li_"+c}
                                    image={Util.getMediaUrl(category.ListImage) || ""}
                                    title={languageManager.getLocalizedItem(category.Name)}
                                    subtitle={""}
                                    navlink={"category/"+category.Id+"/"}
                                />)
                            }
                        }
                    }
                }
            }
        }
        //console.log(this.props.data.umbraco);

        let title = (this.props.data.umbraco ? languageManager.getLocalizedItem(this.props.data.umbraco.Name) : "App");
        let tabs = ["Walks","Info"];
        /*if(this.props.data.umbraco.Categories.length > 0) {
            tabs.push("Categories");
        }*/
        return <div className={"AppInstance List"}>
            <Helmet>
                <title>{title}</title>
                {(this.props.data.umbraco && this.props.data.umbraco.AboutImage ? <meta property="og:image" content={(Util.getMediaUrl(this.props.data.umbraco.AboutImage))}/> : "")}
                {(this.props.data.umbraco && this.props.data.umbraco.MenuDescription ? <meta property="og:description" content={this.strip_html_tags(languageManager.getLocalizedItem(this.props.data.umbraco.MenuDescription))}/>:"")}
                <meta property="og:title" content={title}/>
            </Helmet>

            <Titlebar
                title={title}
                setTab={this.setTab.bind(this)} tabs={tabs}
                tab={this.state.tab}
                back={(this.props.routeData && this.props.routeData.params.category ? "../../" : ( this.props.webappData.Id ? "": "../"))}
                webappData={this.props.webappData}
                swapped={this.props.swapped}
            />
            <div className={"Listcontent"}>
                {/*<h1>APP INSTANCE: {this.props.match.params.appid ? this.props.match.params.appid : 0}</h1>
                    <h2>Language: {this.props.match.params.language ? this.props.match.params.language : 0}</h2>
                    <h3>CMS Guide Number: {this.props.data.firebase ? (this.props.data.firebase[this.props.match.params.appid] ? this.props.data.firebase[this.props.match.params.appid].APP_SETTINGS.CMS_GUIDE_NUMBER : "No app with that ID") : ""}</h3>*/}
                <div className={"Tab"+(this.state.tab===0?" Active":"")}>
                    {categories.length === 0 ? walks : categories}
                </div>
                <div className={"Tab"+(this.state.tab===1?" Active":"")}>
                    {serverLang && this.props.data.umbraco  ?
                        <div className="Info" >
                            {this.props.data.umbraco.AboutImage ?
                                <div className={"AboutImage"}>
                                    <img src={Util.getMediaUrl(this.props.data.umbraco.AboutImage)}/>
                                </div>
                                : ""}
                            {(<div className={"AboutText"} dangerouslySetInnerHTML={{__html: this.props.data.umbraco ? LanguageManager.getLocalizedItem(this.props.data.umbraco.MenuDescription):""}}/>)}
                            <ul className={"ExternalLinks"}>
                                {externalLinks}
                            </ul>
                        </div>
                        : ""}
                </div>
                <div className={"Tab"+(this.state.tab===2?" Active":"")}>
                </div>
            </div>

        </div>;
    }

};

export default AppInstance;
