import React, { Component } from "react";
import './AppList.scss';
import Titlebar from '../../components/Titlebar/Titlebar';
import ListItem from '../../components/ListItem/ListItem';
import SwapButton from '../../components/SwapButton/SwapButton';

class AppList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: 0
        }
    }

    componentDidMount() {
        //console.log("AppList Did Mount!");
        //ReactGA.pageview('AppList');
        /* var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']); */
    }

    setTab(i) {
        this.setState({tab: i},null);
    }

    render() {
        //console.log("Server Render");
        let apps = [];
        //console.log(this.props.data)
        if(this.props.data) {
            for(let a in this.props.data.firebase) {
                if(this.props.data.firebase.hasOwnProperty(a)) {
                    //console.log("A: ",a);
                    // let app = this.props.data.firebase[a];
                    //apps.push(<li key={"li_"+a}><NavLink to={a+"/"}>{a}</NavLink></li>)
                    apps.push(<ListItem
                        key={"app_"+a}
                        image={""}
                        title={a}
                        subtitle={""}
                        navlink={a+"/"}
                    />)
                }
            }
        }
        //console.log("APPSLENGTH:",apps.length);
        //console.log("AppList: ", this.props.webappData);
        return (
            <div className={"AppList List"}>
                <Titlebar
                    title={"App List"}
                    setTab={this.setTab.bind(this)}
                    tabs={["Locations","Info"]}
                    tab={this.state.tab}
                    webappData={this.props.webappData}
                    swapped={this.props.swapped}
                />
                <div className={"Listcontent"}>
                    <div className={"Tab"+(this.state.tab===0?" Active":"")}>
                        {apps}
                    </div>
                    {/*<OtherObject useSuspense={false}/>*/}
                    <div className={"Tab"+(this.state.tab===1?" Active":"")}>
                        <div className="Info">
                            OnSpotStory Webapp
                        </div>
                    </div>
                </div>
                <SwapButton text={"Map"} swap={this.props.swap}/>
            </div>
        );
    }
}

export default AppList;
