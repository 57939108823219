import React, { Component } from "react";
//import {FormattedMessage, IntlShape, injectIntl, defineMessages } from 'react-intl';
import { Map as LeafletMap, TileLayer, Marker, Popup, ImageOverlay} from "react-leaflet-extendable";
import './Map.scss';
import {NavLink} from "react-router-dom";
//require('./Map.scss');
import ExtendedMarker from "./ExtendedMarker";
import SwapButton from '../../components/SwapButton/SwapButton';
import languageManager from "../../providers/data-manager/language-manager";
import {Util} from "../../providers/Utils";
import LanguageManager from "../../providers/data-manager/language-manager";

const Leaflet = window.L;
/*const messages = defineMessages({
    bye: {
        id: 'otherObject-bye',
        defaultMessage: 'Bye bye!',
    },
});*/
const TAG = "Map";
export default class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lat: 59.309040,
            lng: 18.091602,
            zoom: 1,
            currentOpenMarker: null,
            realZoom: 0
        }
        if(props.access_token && props.access_token !== "" && props.mapBoxUser && props.mapBoxUser !== "") {
            this.state.access_token = props.access_token; // || 'pk.eyJ1IjoidGJ0aGVncjgxIiwiYSI6ImNqdG80YTFnNzA1cHg0OXF0ZW81eDBtemkifQ.zdnYP1ufAPburgumLeECFw'
            this.state.tileLayerUrl = props.tileLayerUrl; // || 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={access_token}'
            this.state.mapBoxUser = props.mapBoxUser; // || 'mapbox.streets'
        }
        this.map = null;
        this.markerRefs = {};
        //console.log("MAP:",props);
        //console.log("MapState: ", this.state.access_token, this.state.tileLayerUrl, this.state.mapBoxUser);
        //console.log("LayerURL: ", this.state.titleLayerUrl);
    }

    /*openMarker(i) {
        //onsole.log("OpenMarker:",this.markerRefs[i]);
        console.log("OPEN MARKER - ",this.state.currentOpenMarker, i);
        if(this.state.currentOpenMarker !== i) {
            console.log("CUrrent Open Marker is different from i");
            if(this.markerRefs[i]) {
                this.markerRefs[i].click();
                console.log(this.markerRefs[i]);
                console.log("Clicking Ref");
            }
            else {
                console.log("MarkerRef isnt set");
                console.log(this.markerRefs);
            }
        } else {
            console.log("Marker is already opened");
        }
        //this.markerRefs[i].click();
    }

    setMarkerClicked(i) {
        console.log("Marker ",i," clicked");
        this.setState({currentOpenMarker: i},null);
    }
*/
    componentDidUpdate(prevProps) {
        /*if(prevProps.activeMarker !== this.props.activeMarker) {
            this.plzzOpen = this.props.activeMarker;
            this.openMarker(this.props.activeMarker);
        }*/
        //console.log(TAG, prevProps.markers, this.props.markers, !!(prevProps.markers !== this.props.markers));
        const page = this.props.routeData.params.appid+"_"+this.props.routeData.params.walkid+"_"+this.props.routeData.params.location;
        const old_page = prevProps.routeData.params.appid+"_"+prevProps.routeData.params.walkid+"_"+prevProps.routeData.params.location;
        if(page !== old_page) {
            console.log(TAG, "Different page, close all open popups!", page, old_page);
            if(this.map.leafletElement && this.map.leafletElement.closePopup) this.map.leafletElement.closePopup();

            /*const mark = this.props.markers.find(marker => marker.id === parseInt(this.props.activeMarker));
            if(mark) {
                console.log(TAG, "Panning map to new location", mark);
                this.map.leafletElement.panTo(new Leaflet.LatLng(mark.lat, mark.lng));

                this.map.leafletElement.once('moveend', () => {
                    console.log(TAG, "Allegedly, moveing is done", this.markerRefs[mark.id]);
                    if(this.markerRefs[mark.id] && this.markerRefs[mark.id].leafletElement) {
                        this.markerRefs[mark.id].leafletElement.openPopup();
                    }
                    else {
                        console.error("Found no popup to open");
                    }
                    /*const targets = this.map.leafletElement;
                    //console.log(TAG, "Targets: ", targets);
                    for(const t in targets) {
                        if(targets.hasOwnProperty(t)) {
                            const target = targets[t];
                            console.log(TAG, "Target: ", target.options.position, "Compared to: ", [mark.lat, mark.lng]);
                            if(target.options.position && target.options.position[0] === mark.lat && target.options.position[1] === mark.lng) {
                                target.openPopup();
                                break;
                            }
                        }
                    }* /
                });

            } else {
                console.log(TAG, "No marker found?", this.props.activeMarker);
            }*/

        }
    }

    componentDidMount() {
        /*if(this.props.activeMarker !== this.plzzOpen) {
            console.log("We have a activeMarker and its not plzzOpen", this.props.activeMarker)
            this.plzzOpen = this.props.activeMarker;
            this.openMarker(this.plzzOpen);
            console.log("Trying to openMarker ",this.plzzOpen);
        }*/
        //const zoom = this.map && this.map.leafletElement.getZoom();
        //console.log(TAG, "Setting RealZoom to: ", zoom);
        //this.setState({realZoom: zoom});
    }

    render() {
        //console.log(TAG, "Rendering!");
        let position = [this.state.lat, this.state.lng];
        //console.log(TAG, "Initial", {position});
        let classes = "map ";
        let btnclasses = "mapSwapBtn ";
        if(this.props.side === 1)
        {
            classes += "swapped";
            btnclasses += "swapped";
        }

        let markers = [];
        let markerPos = [];
        let markerInFocus = null;
        //console.log("Markers:",this.props.markers);

        let oss_marker = this.customMarker('/marker2.png');
        for(let i in this.props.markers)
        {
            if(this.props.markers.hasOwnProperty(i)) {
                let mark = this.props.markers[i];
                //console.log("Id: ",mark.id+"", this.props.activeMarker, mark.title, (this.props.activeMarker === mark.id+""));
                if(isNaN(mark.lat) || isNaN(mark.lng)) {
                    //console.log("Mark contains not numbers");
                    continue;
                }
                let pos = [mark.lat, mark.lng];
                //console.log("MARK:",mark, pos);
                let dupe = false;
                for(let old in markerPos) {
                    if(markerPos.hasOwnProperty(old)) {
                        if(markerPos[old][0] === pos[0] && markerPos[old][1] === pos[1]) {
                            dupe = true;
                            break;
                        }
                    }
                }
                if(!dupe) {
                    markerPos.push(pos);
                }
                //console.log(TAG, this.props.activeMarker, mark.id, markerInFocus);
                if(this.props.activeMarker === mark.id+"") {
                    //console.log(TAG, "Are the same, setting marker In Focus");
                    markerInFocus = pos;
                }
                //<img style={{width: "80px", height: "50px"}} src={mark.imgurl}/>
                //console.log("Marker Media: ", mark.imgurl);
                const mediaUrl = mark.imgurl ? Util.getMediaUrl(mark.imgurl) : null;
                //console.log("Marker MediaURL: ", mediaUrl);
                markers.push(
                    <ExtendedMarker
                        key={"marker"+i}
                        position={pos}
                        onClick={function(i) {this.props.history.push(this.props.routeData.params.location ? '../'+i+"/" : i+'/')}.bind(this, mark.id)}
                        openPopup={(this.props.activeMarker === mark.id+"")}
                        icon={oss_marker}
                        ref={(ref) => { this.markerRefs[mark.id] = ref; }}
                    >
                        <Popup>
                            <div className={"wrap"} onClick={this.props.swap}>
                                {mediaUrl ? <div className={"img"} style={{backgroundImage: "url("+mediaUrl+")"}}/> : <></>}
                                {mark.link ? <NavLink to={mark.link}>{languageManager.getLocalizedItem(mark.title)}</NavLink> : <div className={"title"}>{languageManager.getLocalizedItem(mark.title)}</div>}
                            </div>
                        </Popup>
                    </ExtendedMarker>
                );
            }
        }
        // console.log("Markers: ", markers);
        //console.log("MARKERREFS:",this.markerRefs);

        //console.log("Marker Pos: ",markerPos);
        //let group = new Leaflet.featureGroup(markerPos);
        //console.log(group);

        //const bounds = (markerPos.length > 0 ? Leaflet.latLngBounds(markerPos) : null);
        //const bounds = group.getBounds();
        //console.log("MarkerPos Length: ",markerPos.length, markerPos);
        //console.log("Current Open Marker: ",this.state.currentOpenMarker, "PlzzOpen: ",this.plzzOpen);
        let bounds;
        let zoom = this.getMapZoom() || this.state.zoom;
        //console.log(TAG, {"autoCenter": this.props.autoCenter});
        if(this.props.autoCenter) {
            //console.log(TAG, "MAP Current ZOOM: ", zoom);
            if(markerPos.length > 1) {
                bounds = new Leaflet.LatLngBounds(markerPos);
                console.log(TAG, "Setting bounds: ", bounds);
            }
            else if(markerPos.length === 1) {
                position = markerPos[0];
                bounds = null;
                zoom = 19;
                //console.log(TAG, "Nulling Bounds: ", bounds);
            } else {
                bounds = null;
            }
            //bounds = new Leaflet.LatLngBounds(this.props.mapImageBounds)
            //zoom = this.props.initZoom;
        }
        else {
            //position = this.props.centerPos;
            //console.log(TAG, "Second", {position});
            console.log("Don't Autocenter");
            if(markers.length > 0) {
                const posArray = markers.map(m => Leaflet.latLng(m.props.position));
                //console.log("Bounds on Markers", posArray);
                //bounds = Leaflet.LatLngBounds(posArray);
                bounds = posArray;
                console.log("Bounds: ", bounds);
            } else {
                bounds = null;
            }
            zoom = this.props.initZoom;
        }
        if(markerInFocus) {
            position = markerInFocus;
            //console.log(TAG, "Marker in focus", markerInFocus);
        }

        //console.log("Bounds",bounds);
        /*markers.push(
        <Marker position={position}>
          <Popup>
            A pretty CSS3 popup. <br/> Easily customizable.
          </Popup>
        </Marker>
        );*/
        //map.fitBounds(bounds);

        //console.log("In Render, checking plzzOpen: ",this.plzzOpen, " and currentOpenMarker: ", this.state.curentOpenMarker);
        if(this.plzzOpen && this.state.currentOpenMarker !== this.plzzOpen) {
            /*console.log("PlzzOpen is set and currentOpenMarker isnt plzzOpen", this.plzzOpen);
            this.openMarker(this.plzzOpen);
            console.log("Called to open plzzOpen");
            this.plzzOpen = null;
            console.log("nulled plzzOpen");*/
        }

        //console.log(TAG, "TILE LAYER: ", this.state.tileLayerUrl, this.state.mapBoxUser, this.state.access_token);
        //console.log(TAG, "MapImage: ",this.props.mapImage, this.props.mapImageBounds);
        //console.log(TAG, "Rendering map with: ", position, zoom, bounds);
        //console.log(TAG, {position});
        //console.log(TAG, {zoom});
        //console.log(TAG, {bounds});

        return (
            <div className={"Map"+(this.props.moveLeft?" moveLeft":"")}>
                <LeafletMap
                    center={position}
                    zoom={zoom}
                    bounds={bounds}
                    ref={(ref) => { this.map = ref; }}
                >
                    {this.state.tileLayerUrl && this.state.tileLayerUrl !== "" ? <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url={this.state.tileLayerUrl}
                        id={this.state.mapBoxUser}
                        access_token={this.state.access_token}
                    /> : ""}
                    {!Util.isEmpty(this.props.mapImage) && this.props.mapImageBounds ? <ImageOverlay
                        url={Util.getMediaUrl(LanguageManager.getLocalizedItem(this.props.mapImage))}
                        bounds={new Leaflet.LatLngBounds([this.props.mapImageBounds])}
                    /> : ""}
                    {markers}
                </LeafletMap>
            </div>
        );
        // 45.352821,-76.046281,10
        // 45.350563,-76.048982,19

        // 45.352007,-76.046356,18
        // 45.350932,-76.0488,18
    }

    handleZoomstart = (map) => {
        //console.log(this.map && this.map.leafletElement);
    };
    getMapZoom() {
        return this.map && this.map.leafletElement.getZoom();
    }

    customMarker(markerlink, size = null) {
        return new Leaflet.Icon({
            iconUrl: markerlink,
            iconRetinaUrl: markerlink,
            iconAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: (size ? new Leaflet.Point(size[0], size[1]) : new Leaflet.Point(30, 41)),
            popupAnchor: [0, 0],
            className: 'leaflet-div-icon'
        });
    }
    /*
    <img
    src="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.5.1/images/marker-icon.png"
    class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive"
    alt=""
    tabindex="0"
    style="margin-left: -12px; margin-top: -41px; width: 25px; height: 41px; transform: translate3d(602px, 336px, 0px); z-index: 336;">
     */
}
