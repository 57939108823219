import React, { Component } from "react";
import {Helmet} from "react-helmet";
import "./Walk.scss";
import Titlebar from '../../components/Titlebar/Titlebar';
import LeftList from '../LeftList/LeftList';
import LeftListMenu from '../../components/LeftListMenu/LeftListMenu';
import ListItem from '../../components/ListItem/ListItem';
import Fullscreen from '../Fullscreen/Fullscreen';
import SwapButton from '../../components/SwapButton/SwapButton';
import {ReactComponent as AudioIcon} from '../../assets/Icons/audio.svg';
import languageManager from "../../providers/data-manager/language-manager";
import {Util} from "../../providers/Utils";
import AppInstance from "../AppInstance/AppInstance";

const TAG = "Walk";
class Walk extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: 0,
            fullscreen: null,
            fullscreen_media: [],
            fullscreen_captions: [],
            fullscreen_index: 0
        }
        this.musicPlayer = null;

        this.currentListItem = React.createRef();
        this.list = React.createRef();
    }

    componentDidMount() {
        console.log(TAG, "Did Mount!");
        //console.log("Do we have the data we need?");
        //console.log(this.props.data.umbraco, this.props.match.params);
        var _paq = window._paq = window._paq || [];
        if(this.props.match.params.app && !this.props.match.params.language) {
            //ReactGA.pageview('Walk');
            /* _paq.push(['trackPageView']); */
        }
        else if(this.props.match.params.app && this.props.match.params.language && !this.props.match.params.location) {
            //ReactGA.pageview('Walk');
            /* _paq.push(['trackPageView']); */
        }
        else if(this.props.match.params.app && this.props.match.params.language && this.props.match.params.location) {
            //ReactGA.pageview('WalkLocation');
            /* _paq.push(['trackPageView']); */
        }
        this.props.makeLanguageList();

        if(this.props.data.umbraco && !this.props.match.params.language) {
            //console.log("We got data but no language");
            let walk = this.getCurrentWalk();

            //this.props.history.push(walk.DefaultLanguage+"/");
            let automaticLanguage = walk.DefaultLanguage;
            let languages = walk.Languages;
            const serverLang = this.props.routeData ? this.props.routeData.params.language : this.props.data.language;
            //console.log("LanguageVars: ",walk.DefaultLanguage, languages, serverLang);
            if(languages.indexOf(serverLang) > -1) {
                automaticLanguage = serverLang;
            }
            if(languages.length < 1) {
                automaticLanguage = serverLang;
            }
            //console.log("Pushing history to : ", automaticLanguage+"/");
            this.props.history.push(automaticLanguage+"/");
        }
        else if(!this.props.data.umbraco && this.props.match.params.language) {
            console.log(TAG, "Umbraco Data needed, download it now!", this.props.match.params.language);
            this.props.downloadUmbracoData();
        }
        else if(this.props.data.umbraco && this.props.data.umbraco.LanguageCode !== this.props.match.params.language) {
            //console.log("Different language of Umbraco Data needed, download it now!");
            // this.props.downloadUmbracoData();
        } else if(this.props.data.umbraco) {
            //console.log("We got data");
            const walk = this.getCurrentWalk();
            if(walk && walk.Locations.length === 0) {
                this.setState({tab: 1});
            }
            /* We don't have to do any of this in V3 API
            let walk = this.getCurrentWalk();
            //console.log(this.props.data.umbraco.LanguageCode, this.props.match.params.language);
            if(walk) {
                if(walk.Locations.length > 0 && walk.Locations[0].Text.indexOf("http") === 0) {
                    //console.log("First word of Text is a link, lets assume we havnt downloaded the location text");
                    this.props.downloadUmbracoData().then(function(data) {
                        //console.log("DownloadedUmbracoData:",data);
                    });
                }
                else {
                    //console.log("First word wasnt a link: ", walk.Locations[0].Text, walk.Locations[0].Text.indexOf("http"));
                }
            }
            else {
                //console.log("NO Walk");
            }*/
        } else {
            //console.log("No Umbraco Data");
        }
    }
    componentDidUpdate(prevProps) {
        if(prevProps.match.params) {
            //this.props.setActive(this.props.match.params.appid, this.props.match.params.language, this.props.match.params.walkid, this.props.match.params.location);
            /* if(prevProps.match.params.language !== this.props.match.params.language) {
                // New language!
                if(this.props.data.umbraco && this.props.data.umbraco.LanguageCode !== this.props.match.params.language) {
                    //console.log("Different language of Umbraco Data needed, download it now!");
                    this.props.downloadUmbracoData();
                }
            } */ // No need in API3
            if(prevProps.match.params.location !== this.props.match.params.location) {
                //console.log("New location");
                this.scrollElementIntoViewIfNeeded();
            }
            var _paq = window._paq = window._paq || [];
            if(this.props.match.params.app && !this.props.match.params.language) {
                console.log("At Walk");
                //ReactGA.pageview('Walk');
               /*  _paq.push(['trackPageView']); */
            }
            else if(this.props.match.params.app && this.props.match.params.language && !this.props.match.params.location) {
                //ReactGA.pageview('Walk');
                console.log("At Walk");
                /* _paq.push(['trackPageView']); */
            }
            else if(this.props.match.params.app && this.props.match.params.language && this.props.match.params.location) {
                //ReactGA.pageview('WalkLocation');
                console.log("At WalkLocation");
                /* _paq.push(['trackPageView']); */
            }
        }
    }


    scrollElementIntoViewIfNeeded(domNode) {
        console.log("OffsetTop:",this.currentListItem.current ? this.currentListItem.current.offsetTop:"NO");
        console.log("Scroll: ",this.list.current.offsetHeight, this.list.current.scrollTop);

        console.log((this.currentListItem.current.offsetTop > (this.list.current.offsetHeight+this.list.current.scrollTop)), (this.list.current.offsetHeight+this.list.current.scrollTop));
        this.list.current.scrollTo(0, this.currentListItem.current.offsetTop - this.list.current.offsetHeight + this.currentListItem.current.offsetHeight)
    }

    setTab(i) {
        this.setState({tab: i},null);
    }

    openFullscreen(media,captions,index) {
        console.log("Open ",index, "In fullscreen");
        this.setState({fullscreen: true, fullscreen_media: media, fullscreen_captions: captions, fullscreen_index: index}, null);
    }
    closeFullscreen() {
        this.setState({fullscreen: null, fullscreen_caption: null},null);
    }
    setMusicPlayer(ref) {
        this.musicPlayer = ref;
    }


    nextLocation() {
        //console.log("NExt Location",this.props.match);
        let currentLocationID = parseInt(this.props.match.params.location);
        //console.log("Current ID:", currentLocationID);
        let listOfLocations = [];
        let walk = this.getCurrentWalk();
        if(!walk) { return false; }

        let newId;
        for(let l = 0; l < walk.Locations.length; l++) {
            //console.log("walkLocations:",walk.Locations[l].Id, currentLocationID);
            if(walk.Locations[l].Id === currentLocationID && walk.Locations[l+1]) {
                newId = walk.Locations[l+1].Id;
            }
        }
        if(newId) {
            //console.log("New ID:",newId);
            this.props.history.push('../'+newId+'/');
        }
    }
    previousLocation() {
        //console.log("Previous Location");
        let currentLocationID = parseInt(this.props.match.params.location);
        //console.log("Current ID:", currentLocationID);
        let listOfLocations = [];
        let walk = this.getCurrentWalk();
        if(!walk) { return false; }

        let newId;
        for(let l = 0; l < walk.Locations.length; l++) {
            //console.log("walkLocations:",walk.Locations[l].Id, currentLocationID);
            if(walk.Locations[l].Id === currentLocationID && walk.Locations[l-1]) {
                newId = walk.Locations[l-1].Id;
            }
        }
        if(newId) {
            //console.log("New ID:",newId);
            this.props.history.push('../'+newId+'/');
        }
    }

    getCurrentWalk() {
        // console.log(TAG, "Get Current Walk: ", this.props.data);
        if(this.props.data && this.props.data.umbraco) {
            for(let w in this.props.data.umbraco.Walks) {
                //console.log("WALK: ",w,this.props.data.umbraco.Walks[w].Id,this.props.match.params.walkid);
                if(this.props.data.umbraco.Walks.hasOwnProperty(w) && this.props.data.umbraco.Walks[w].Id === parseInt(this.props.match.params.walkid)) {
                    return this.props.data.umbraco.Walks[w];
                }
            }
        }
        else {
            console.log(TAG, "No Umbraco data - No walk");
        }
        return null;
    }

    render() {
        let umbraco_walk = this.getCurrentWalk();
        //console.log("DATA:",this.props.data);
        //console.log("Umbraco Walk: ",umbraco_walk);
        //console.log("MATCH:",this.props.match);
        let locations = [];
        let locationData = null;

        let firstLocation = null;
        let lastLocation = null;

        const language = this.props.match.params.language;

        if(umbraco_walk) {
            for(let l = 0; l < umbraco_walk.Locations.length; l++) {
                let Location = umbraco_walk.Locations[l];
                if(l === 0) { firstLocation = Location.Id}
                if(l === umbraco_walk.Locations.length-1) { lastLocation = Location.Id}
                //locations.push(<li key={"location_"+l} title={Location.Text}><NavLink to={(this.props.match.params.location ? "../"+Location.Id+"/" : Location.Id+"/")}>{Location.Name}</NavLink></li>)
                let isCurrentLocation = (Location.Id === parseInt(this.props.match.params.location));
                locations.push(
                    <ListItem
                        key={"location_"+l}
                        image={Location.Images.length > 0 ? Util.getMediaUrl(Location.Images[0]) : ""}
                        title={languageManager.getLocalizedItem(Location.Name)}
                        subtitle={languageManager.getLocalizedItem(Location.Sound) ? <AudioIcon/> : ""}
                        navlink={(this.props.match.params.location ? "../"+Location.Id+"/" : Location.Id+"/")}
                        marked={isCurrentLocation}
                        forwardRef={this.currentListItem}
                        numbered={this.props.data.umbraco.Numbered ? Location.NumPadText : false}
                    />)
                if(isCurrentLocation) {
                    // console.log("Matching location", Location);
                    locationData = Location;
                }

            }
        }
        //console.log("First: ",firstLocation, "Last: ", lastLocation, "Current: ", locationData.Id, "Is First? ", (locationData.Id === firstLocation), "Is Last?", (locationData.Id === lastLocation));

        if(!umbraco_walk) {
            console.log(TAG, "No Umbraco Walk Data, return empty html");
            return <div>Walk</div>
        }
        const walkName = (umbraco_walk && umbraco_walk.Name) ? languageManager.getLocalizedItem(umbraco_walk.Name) : "Walk";
        // console.log(TAG, "WALK NAME: ", walkName);
        const tabs = [];
        if(umbraco_walk && umbraco_walk.Locations && umbraco_walk.Locations.length > 0) {
            tabs.push("Locations");
        }
        tabs.push("Info");
        return <div className={"Walk List"}>
            <Helmet>

                <title>{walkName} {locationData ? (" - " + languageManager.getLocalizedItem(locationData.Name)) : ""}</title>
                <meta property="og:title" content={walkName}/>
                {(umbraco_walk ? <meta property="og:description" content={languageManager.getLocalizedItem(umbraco_walk.Description)}/> :"")}
                {(umbraco_walk ? <meta property={"og:image"} content={Util.getMediaUrl(umbraco_walk.ListImage)}/> : "")}
            </Helmet>

            <Titlebar
                title={walkName ? walkName : "Walk"}
                setTab={this.setTab.bind(this)} tabs={tabs}
                tab={this.state.tab}
                smackback={(this.props.match.params.language?(locationData ? "../../../":"../../"):"../")}
                back={(locationData ? "../../":"../")}
                webappData={this.props.webappData}
                swapped={this.props.swapped}
            />
            <div className={"Listcontent"} ref={this.list}>
                {/*<h1>WALK: {this.props.match.params.walkid ? this.props.match.params.walkid : 0}</h1>
                    <h2>Location: {this.props.match.params.location ? this.props.match.params.location : 0}</h2>
                    */}
                <div className={"Tab"+(this.state.tab===0?" Active":"")}>
                    {locations}
                </div>
                <div className={"Tab"+(this.state.tab===1?" Active":"")}>
                    {umbraco_walk ? <div className={"Info"} dangerouslySetInnerHTML={{__html: languageManager.getLocalizedItem(umbraco_walk.Description)}}/>:""}
                </div>
            </div>
            {locationData ? <LeftList
                key={"leftlist_"+locationData.Id}
                moveLeft={this.props.moveLeft}
                locationData={locationData}
                openFullscreen={this.openFullscreen.bind(this)}
                setMusicPlayer={this.setMusicPlayer.bind(this)}
            /> : <></>}
            {locationData ? <LeftListMenu
                key={"menu_"+locationData.Id}
                nextLocation={this.nextLocation.bind(this)}
                previousLocation={this.previousLocation.bind(this)}
                isLast={(locationData.Id === lastLocation)}
                isFirst={(locationData.Id === firstLocation)}
                musicPlayer={this.musicPlayer}
                title={locationData.Name}
                sound={locationData.Sound}
            /> : <></>}

            {this.state.fullscreen ? <Fullscreen
                media={this.state.fullscreen_media || []}
                captions={this.state.fullscreen_captions || []}
                closeFullscreen={this.closeFullscreen.bind(this)}
                index={this.state.fullscreen_index}
            />: <></>}
        </div>;
    }

};

export default Walk;
