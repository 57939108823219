import React, {Component} from 'react';
import './SearchResult.scss';
import {IntlProvider} from "react-intl";
import {NavLink} from "react-router-dom";
import {search} from "../../providers/search-provider/search-provider";

class SearchResult extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log("SEARCH RESULT!!!!!", this.props.searchResult);
        if(this.SearchInput) {
            this.SearchInput.focus();
        }
    }

    onChange(e) {
        let value = e.target.value;
        console.log("onChange value", value);
        if(value === "") {
            this.props.setSearchResult(null);
            window.clearTimeout(this.searchTimer);
            console.log("Nulling Search Result");
        } else {
            if(this.searchTimer) {
                console.log("Clearing Timeout");
                window.clearTimeout(this.searchTimer);
            }
            // After the timeout, actually do the search
            this.searchTimer = window.setTimeout(this.doSearch.bind(this, e.target.value), 1500);
        }
    }

    doSearch(input) {
        console.log("Doing Search with: ", input);
        search(this.props.appId, input, this.props.routeData.params.language).then(function(result) {
            this.props.setSearchResult(result);
            //this.setState({searchResult: result ? result : [], showSearchResult: (result && result.length > 0)})
            //console.log("Setting results: ", result, (result && result.length > 0));
        }.bind(this));
    }

    render() {
        let results = [];

        for(let res in this.props.searchResult) {
            if(this.props.searchResult.hasOwnProperty(res)) {
                let result = this.props.searchResult[res];
                switch(result.DocumentType) {
                    case "Application":
                    case "event":
                    case "InfoPin":
                        continue;
                }
                results.push(
                    <div className={"ResultItem"}>
                        <NavLink to={"/"+this.props.params.appid+"/"+result.WalkLang+"/"+(result.WalkId+"/" ? result.WalkId+"/" + (result.LocationId ? result.LocationId+"/":""): "")} onClick={this.props.showSearchResult.bind(this,false)}>
                            <div className={"Image"} style={{"backgroundImage": "url("+result.Image+")"}}/><div className={"Text"}>{result.Name}</div><div className={"Type"}>{result.DocumentType}</div>
                        </NavLink>
                    </div>
                )
            }
        }
        let mobile = (window.innerWidth<900);
        if(mobile) {
            return (
                <div className={"SearchResult Mobile"}>
                    <div className={"SearchInput"}>
                        <input onChange={this.onChange.bind(this)} type="search" placeholder="Search" ref={(input) => { this.SearchInput = input; }} />
                    </div>
                    {results}
                </div>
            )
        }
        else {
            return (
                <div className={"SearchResult"} onMouseOver={this.props.setOverSearch.bind(this, true)} onMouseLeave={this.props.setOverSearch.bind(this, false)} onTouchStart={this.props.setOverSearch.bind(this, true)} onTouchEnd={this.props.setOverSearch.bind(this, false)}>
                    {results}
                </div>
            );
        }
    }
}


export default SearchResult;
