import React, {Component} from 'react';
import './LanguageSelect.scss';
import {IntlProvider} from "react-intl";
import languageManager from "../../providers/data-manager/language-manager";
import {NavLink} from "react-router-dom";
const TAG = "LanguageSelect";
class LanguageSelect extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    langCodeToName(langcode) {
        console.log("Langcode: ", langcode);
        switch(langcode) {
            case "en":
                return "English";
            case "sv":
                return "Svenska";
            case "fi":
                return "Suomi";
            case "de":
                return "Deutsch";
            case "fr":
                return "Français";
            case "ru":
                return "русский";
            case "es":
                return "Español";
            case "ar":
                return "عربى";
            case "iu":
                return "ᐃᓄᒃᑎᑐᑦ";
            case "zh":
                return "中國人";
        }
        return langcode;
    }

    render() {
        //console.log("Current Lang: ",this.props.currentLang);
        console.log(TAG, "Language List: ", languageManager.languages);
        let languages = [];
        for(let t = 0; t < languageManager.languages.length; t++) {
            let lang = languageManager.languages[t];
            //let linkTarget = this.props.appid ? "/"+this.props.appid + "/" + lang+"/" : this.props.currentLang ? "../"+lang+"/" : lang+"/";
            let linkTarget = "/";
            if(this.props.appid) {
                linkTarget += this.props.appid+"/";
            }
            linkTarget += lang+"/";
            if(this.props.walkid && this.props.walk.Languages.indexOf(lang) > -1) {
                linkTarget += this.props.walkid+"/";

                if(this.props.location) {
                    linkTarget += this.props.location+"/";
                }
            }
            /*if(this.props.location) {
                linkTarget += this.props.location+"/";
            }*/
            languages.push(<div key={"lang_"+t} className={"langoption"} style={{fontWeight: (languageManager.fallback_language === lang ? "bold": "normal")}} onClick={this.props.toggleLanguageSelect}><NavLink to={linkTarget}>{this.langCodeToName(languageManager.languages[t])+" >"}</NavLink></div>);
        }
        return (
            <div className={"LanguageSelect"}>
                {/*<div onClick={this.props.toggleLanguageSelect}>CLOSE</div>*/}
                <div className="welcome">Select Language</div>
                {languages}
            </div>
        );
    }
}


export default LanguageSelect;
