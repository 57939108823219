class MediaContainer {
    Id: number
    Url: string
    Hash: string
    Alt: { [key: string]: string; }
    Download: boolean
    NoCompression: boolean
    Bytes: number
}

export class Util {
    static strip_html_tags = (str) =>
    {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    }

    static getMediaUrl = (mediaContainer: MediaContainer, forceNoCompression: boolean = false) => {
        if(mediaContainer && mediaContainer.Url) {
            const url = mediaContainer.Url + (forceNoCompression ? "?no_compression" : "");
            // console.log("UTILS - Returning Media URL: ", url);
            return url;
        }
        return "";
    }

    static isEmpty = (obj) => {
        if(obj) {
            return Object.keys(obj).length === 0;
        }
        return true;
    }

    static deepAssign = (a, b) => {
        const hasOwnProperty = Object.prototype.hasOwnProperty
        const propIsEnumerable = Object.prototype.propertyIsEnumerable
        function assignKey(to, from, key) {
            const val = from[key]

            if (val === undefined || val === null) {
                return
            }
            if (hasOwnProperty.call(to, key)) {
                if (to[key] === undefined || to[key] === null) {
                    throw new TypeError("Cannot convert undefined or null to object (" + key + ")")
                }
            }
            if (!hasOwnProperty.call(to, key) || !isObj(val)) {
                to[key] = val
            } else {
                to[key] = assign(Object(to[key]), from[key])
            }
        }

        function isObj(x) {
            const type = typeof x
            return x !== null && (type === "object" || type === "function")
        }

        function assign(to, from) {
            if (to === from) {
                return to
            }
            from = Object(from)
            for (const key in from) {
                if (hasOwnProperty.call(from, key)) {
                    assignKey(to, from, key)
                }
            }

            if (Object.getOwnPropertySymbols) {
                const symbols = Object.getOwnPropertySymbols(from)
                for (const i of symbols) {
                    if (propIsEnumerable.call(from, symbols[i])) {
                        assignKey(to, from, symbols[i])
                    }
                }
            }
            return to
        }
        return assign(a, b)
    }
}
