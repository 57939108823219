import React, {Component} from 'react';
import './LeftList.scss';
import {IntlProvider} from "react-intl";
import {NavLink} from "react-router-dom";
import ImagePlayer from "../../components/ImagePlayer/ImagePlayer";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import {ReactComponent as BackArrowIcon} from '../../assets/Icons/play4.svg';
import {Helmet} from "react-helmet";
import languageManager from "../../providers/data-manager/language-manager";
import {Util} from "../../providers/Utils";

class LeftList extends Component {
    constructor(props) {
        super(props);
        this.musicPlayer = React.createRef();
        this.props.setMusicPlayer(this.musicPlayer);
    }

    componentDidMount() {
        /* This calls a function that moves the Leaflet copyright box to the left when Leftlist is displayed */
        this.props.moveLeft(true);
        console.log("LeftList Did Mount");
        /* var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']); */
    }
    componentWillUnmount() {
        /* And this moves it back to the right when LeftList disappears */
        this.props.moveLeft(false);
    }

    render() {
        let locationData = this.props.locationData;
        //console.log("LeftListLocationData:",locationData);
        console.log("LocationData Video: >", locationData.Video, "<", !!locationData.Video, typeof locationData.Video, Util.isEmpty(locationData.Video));

        return (
            <div className={"LeftList"}>
                <Helmet>
                    <title>{languageManager.getLocalizedItem(locationData.Name)}</title>
                    {(locationData.Images && locationData.Images.length > 0 ? <meta property="og:image" content={Util.getMediaUrl(locationData.Images[0])}/> : "")}
                    <meta property="og:description" content={languageManager.getLocalizedItem(locationData.Text)}/>
                    <meta property="og:title" content={languageManager.getLocalizedItem(locationData.Name)}/>
                </Helmet>

                <div className="Headr">
                    <div className={"BackBtn"}><NavLink to={"../"}><BackArrowIcon className={"backarrow"}/>Back</NavLink></div>
                    <div className={"Title"}>{languageManager.getLocalizedItem(locationData.Name)}</div>
                </div>
                {!Util.isEmpty(locationData.Video) ? <VideoPlayer video={languageManager.getLocalizedItem(locationData.Video)} videoPoster={languageManager.getLocalizedItem(locationData.VideoThumbnail)}/> : <></>}
                <ImagePlayer
                    images={locationData.Images}
                    captions={languageManager.getLocalizedItem(locationData.ImageCaptions) || []}
                    openFullscreen={this.props.openFullscreen}
                />
                <div className={"Text"} dangerouslySetInnerHTML={{__html: languageManager.getLocalizedItem(locationData.Text)}}/>
                {(languageManager.getLocalizedItem(locationData.Sound) ? <audio ref={this.musicPlayer} src={Util.getMediaUrl(languageManager.getLocalizedItem(locationData.Sound))}/> : <></>)}
            </div>
        );
    }
}


export default LeftList;
