/*import {Icon, Marker as LeafletMarker} from "leaflet";
import React from 'react'

import {LeafletProvider, Marker, withLeaflet} from "react-leaflet";

import MapLayer from "react-leaflet/es/MapLayer";
import {LatLng, MapLayerProps} from "react-leaflet/es/types";
type LeafletElement = LeafletMarker
type Props = {
    icon?: Icon,
    draggable?: boolean,
    opacity?: number,
    position: LatLng,
    zIndexOffset?: number,
} & MapLayerProps

class ExtendedMarker extends MapLayer<LeafletElement, Props> {

    createLeafletElement(props: Props): LeafletElement {
        const el = new LeafletMarker(props.position, this.getOptions(props))
        this.contextValue = { ...props.leaflet, popupContainer: el }
        return el
    }

    componentDidMount() {
        // Call the Marker class componentDidMount (to make sure everything behaves as normal)
        super.componentDidMount();

        // Access the marker element and open the popup.
        this.leafletElement.openPopup();
    }

    updateLeafletElement(fromProps: Props, toProps: Props) {
        if (toProps.position !== fromProps.position) {
            this.leafletElement.setLatLng(toProps.position)
        }
        if (toProps.icon !== fromProps.icon) {
            this.leafletElement.setIcon(toProps.icon)
        }
        if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
            this.leafletElement.setZIndexOffset(toProps.zIndexOffset)
        }
        if (toProps.opacity !== fromProps.opacity) {
            this.leafletElement.setOpacity(toProps.opacity)
        }
        if (toProps.draggable !== fromProps.draggable) {
            if (toProps.draggable === true) {
                this.leafletElement.dragging.enable()
            } else {
                this.leafletElement.dragging.disable()
            }
        }
    }

    render() {
        const { children } = this.props
        return children == null || this.contextValue == null ? null : (
            <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
        )
    }
}

export default withLeaflet(ExtendedMarker);*/

//export default withLeaflet<Props, ExtendedMarker>(ExtendedMarker)


import { ExtendableMarker, withLeaflet } from 'react-leaflet-extendable'

const TAG = "ExtendedMarker";
class ExtendedMarker extends ExtendableMarker {
    componentDidMount() {
        // Call the Marker class componentDidMount (to make sure everything behaves as normal)
        super.componentDidMount();

        // Access the marker element and open the popup.
        console.log(TAG, "Props: ",this.props.openPopup);
        if(this.props.openPopup) {
            console.log(TAG, "Calling OpenPopup in DidMount");
            this.leafletElement.openPopup();
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        //console.log(TAG, "PrevOP: ", prevProps.openPopup, "newOP: ",this.props.openPopup, "Status: ",  this.leafletElement.options.openPopup);
        /*if(this.leafletElement.options.openPopup && !this.props.openPopup) {
            //console.log(TAG, "Calling to Close Popup")
            //this.leafletElement.options.leaflet.map.closePopup();
        }
        else */if(!this.leafletElement.options.openPopup && this.props.openPopup) {
            console.log(TAG, "Calling OpenPopup in DidUpdate");
            this.leafletElement.openPopup();
        }
    }
}
export default withLeaflet(ExtendedMarker)
