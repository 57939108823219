import 'isomorphic-fetch';
import {Util} from "../Utils";
const TAG = "LoadData";
function downloadText(path, object, objId) {
    let url = deepObjectGet(path, object);
    if(url.indexOf("https") !== 0) {
        url = url.replace(/http/, "https");
    }
    console.log("Downloading: ",url);
    return fetch(url)
    .then(res => {
        return res.text()
    })
    .then(data=> {
        //console.log("Data: ",data);
        //return {"objId": objId, "key": key, "value": data};
        return deepObjectSet(path, data, object);
    })
}

function deepObjectSet(path: string, value: any, refObj: any) {
    const pathList = path.split(/[.\[\]]+/).filter(Boolean)
    const last = pathList.pop()
    let tempObj = refObj
    for (const i in pathList) {
        if (pathList.hasOwnProperty(i)) {
            const elem = pathList[i]
            if (!tempObj[elem]) {
                tempObj[elem] = {}
            }
            tempObj = tempObj[elem]
        }
    }
    tempObj[last] = value
    return refObj
}

function deepObjectGet(path: string, refObj: any) {
    const pathList = path.split(/[.\[\]]+/).filter(Boolean)
    const last = pathList.pop()
    let tempObj = refObj
    for (const i in pathList) {
        if (pathList.hasOwnProperty(i)) {
            const elem = pathList[i]
            if (!tempObj[elem]) {
                tempObj[elem] = {}
            }
            tempObj = tempObj[elem]
        }
    }
    return tempObj[last]
}

function downloadAppInfo(firebase, appInstance, walkId) {
    console.log(TAG, "Downloading AppInfo for >",appInstance,"<");
    if(!firebase) return Promise.reject();
    let url = 'https://developerapps.onspotstory.com/umbraco/api/v3app/getappinfo?id='+firebase.APP_SETTINGS.CMS_GUIDE_NUMBER+'&appType=WebApp';
    console.log(TAG, "URL: ",url);
    return fetch(url).then(res => {
        console.log("UmbracoURL returned res: ", !!res);
        return res.json();
    })
    .then(umbraco => {
        console.log(TAG, "AppInfo downloaded", walkId);
        //console.log(data,data2);
        if(walkId) {
            //console.log("WalkID got");
            console.log("Features: ", umbraco.Features);
            let promises = [];

            if(umbraco) {
                //console.log("Got WalkID and Umbraco Data", walkId);

                /* We don't do this anymore with API V3
                for(let w in umbraco.Walks) {
                    if(umbraco.Walks.hasOwnProperty(w)) {
                        let walk = umbraco.Walks[w];
                        //console.log(walk.Id, parseInt(walkId));
                        if(walk.Id === parseInt(walkId)) {
                            /*
                                Download all data for dis walk
                            * /
                            console.log("Walk Description: ", walk.Description);
                            if(walk.Description) {
                                //promises.push(downloadText(walk, "Description", w))
                                promises.push(downloadText("Description", walk, w));
                            }
                            for(let l in walk.Locations) {
                                if(walk.Locations.hasOwnProperty(l)) {
                                    let location = walk.Locations[l];
                                    if(location.Text) {
                                        //console.log("We got a locationText", "Locations[" + l + "].Text", location.Text);
                                        promises.push(downloadText("Locations[" + l + "].Text", walk, l));
                                    }
                                }
                            }

                        }
                    }
                }*/
            }
            //console.log("Promises length: ",walkPromises.length);
            return Promise.all(promises).then(dataArr => {
                // render our app, do something with dataArr, send response
                //console.log("All promises done");
                //console.log(dataArr);
                /*for(let p in dataArr) {
                    if(dataArr.hasOwnProperty(p)) {
                        let pdata = dataArr[p];
                        //umbraco.Walks[pdata.objId][pdata.key] = pdata.value

                    }
                }*/


                //console.log("Umbraco updated, returning");
                return {firebase, umbraco}
            });

        }
        else {
            console.log("No Walk ID");
            return {firebase, umbraco}
        }
    })
}

export function downloadFirebase() {
    console.log(TAG, "Download Firebase");
    return fetch(`https://project-1832415814160681136.firebaseio.com/config.json`)
    .then(res => {
        return res.json();
    })
}

export function normalizeFirebase(data, appInstance) {
    // Merge configs
    const config: any = {}
    Util.deepAssign(config, data['default'])
    Util.deepAssign(config, data[appInstance])

    return Promise.resolve(config);
}

export function loadData(appInstance, walkId, oldData = null) {
    console.log(TAG, "Load Data");
    //console.log("ResourceType: ",appInstance, languageId, walkId, !!oldData);
    if(oldData) {
        //console.log(TAG, "We got old data", oldData);
        //console.log("Old data: ",oldData);
    }
    let promise;
    if(!oldData) {
        promise = downloadFirebase().then((firebase) => {return normalizeFirebase(firebase, appInstance)});
    } else {
        console.log(TAG, "Old Data already set");
        promise = Promise.resolve(oldData.firebase);
    }

    return promise.then(firebase => {
        // console.log(TAG, "We got Firebase data: ",firebase);
        /*if(appInstance) {
            for(let a in firebase) {
                if(firebase.hasOwnProperty(a)) {
                    if(a !== appInstance && a !== "default") {
                        //delete firebase[a];
                    }
                }
            }
        }*/


        if(appInstance) {
            console.log(TAG, "AppInstance: ", appInstance);
            /*if(firebase[appInstance]) {
               //const test = Object.assign(firebase['default'], firebase[appInstance]);
               //console.log(test);
            }*/
            console.log(TAG, "Got language, download more");
            return downloadAppInfo(firebase, appInstance, walkId).then(function(data) {
                // console.log(TAG, "AppInfo Downloaded: ", data);
                //data.language = oldData.language;
                console.log(TAG, "Returning Data");
                return data;
            }).catch(() => {
                console.log(TAG, "Only Returning Firebase");
                return {firebase};
            });
        }
        else {
            console.log(TAG, "No App only firebase");
            return {firebase}
        }
    });
}
export function loadWebappData(domain) {
    //console.log("LoadWebAppData for ",domain);
    //return fetch(`https://devapps.onspotstory.com/umbraco/api/webapp/find?domain=`+domain)
    return fetch(`https://developerapps.onspotstory.com/umbraco/api/webapp/get?id=`+domain+'&lang=sv')
    .then((res) => {
        //console.log(TAG, "LoadWebappData Response: ", res);
        return res.json();
    })
}
