export function search(appId, input, lang = "sv") {
    console.log("Searching: ",input);
    let resultList = [];

    let url = 'https://developerapps.onspotstory.com/umbraco/api/app/Search?id='+appId+'&query='+input+'&lang='+lang;
    console.log("URL: ",url);
    return fetch(url).then(res => {
        return res.json();
    })
    /*.then(results => {

        return results;
    });*/


}
