import React, {Component} from 'react';
import './ListItem.scss';
import {IntlProvider} from "react-intl";
import {NavLink} from "react-router-dom";

class ListItem extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div
                className={"ListItem"+(this.props.marked? " marked":"")}
                ref={(this.props.marked ? this.props.forwardRef : null)}
            >
                <NavLink to={this.props.navlink}>
                    <div className={"image"} style={{backgroundImage: "url("+this.props.image+")"}}/>
                    <div className={"otherStuff"}>
                        <div className={"title"}>{this.props.numbered ? this.props.numbered + ". " + this.props.title : this.props.title}</div>
                        <div className={"subtitle"}>{this.props.subtitle}</div>
                    </div>
                </NavLink>
            </div>
        );
    }
}


export default ListItem;
