import React, {Component} from 'react';
import './Header.scss';
import {IntlProvider} from "react-intl";
import {ReactComponent as LanguageIcon} from '../../assets/Icons/language2.svg';
import {ReactComponent as MenuIcon} from '../../assets/Icons/menu-01.svg';
import {ReactComponent as SearchIcon} from '../../assets/Icons/search.svg';
import {search} from '../../providers/search-provider/search-provider';
import SearchResult from "../../pages/SearchResult/SearchResult";
import {NavLink} from "react-router-dom";

var TAG = "Header";
class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSearchResult: false,
            searchResult: false
        }
        //console.log("Header Constructor");
        console.log("Features: ", this.props.features);
        //this.search = this.props.features.Search;
        this.language = true;
        this.menu = false;

        this.searchTimer = null;
        //this.overSearch = false;
    }

    showSearchResult(value) {
        this.setState({showSearchResult: (value && this.state.searchResult.length > 0)})
    }

    mouseOver(overSearch) {
        //this.overSearch = overSearch;
        //this.setState({overSearch: overSearch});
        //console.log({overSearch});
        this.props.setOverSearch(overSearch);
    }

    goHome() {
        console.log("Go Home!");
    }

    componentDidMount() {
    }
    componentDidUpdate() {
        /*if(this.props.webappData) {
            console.log("Inside designed webapp, go home to instance", this.props.webappData);
        }
        else {
            console.log("In general purpose webapp, go home to applist");
        }*/
    }

    render() {
        let language = this.language;
        /*if(this.props.routeData) {
            if(!this.props.routeData.params.appid) {
                language = false;
            }
        }*/
        if(this.props.languages.length < 2) {
            language = false;
        }
        //console.log("Header Features: ", this.props.features);
        const customApp = this.props.webappData && this.props.webappData.WebAppConfig && this.props.routeData && this.props.routeData.params;
        console.log(TAG, "Customapp: ", customApp);

        return (
        <div className={"Header"+(this.props.features.Search ?" searchEnabled":"")+(this.language ?" languageEnabled":"")+(this.menu ?" menuEnabled":"")}>
            {/*<div className={"Icon"}/>*/}
            {/*<a href={"/"} className={"wide logo"}><img src={this.props.logoWide}/></a>
            <a href={"/"} className={"smol logo"}><img  src={this.props.logoSmol}/></a>*/}
            <NavLink to={customApp ? ("/"+this.props.routeData.params.appid+"/") : "/"} ><img className={"wide logo"} src={this.props.logoWide}/>
            <img className={"smol logo"} src={this.props.logoSmol}/></NavLink>
            {this.props.features.Search ? <div className={"Search"} onMouseOver={this.mouseOver.bind(this, true)} onMouseLeave={this.mouseOver.bind(this, false)} onBlur={function(){window.setTimeout(this.showSearchResult.bind(this,false),50)}.bind(this)}>
                <div className={"desktopSearch"}><label><input className={"searchText"} /*onKeyUp={this.onSearchEntry.bind(this)}*/ onChange={this.onChange.bind(this)} placeholder="Search" type={"search"}/></label></div>
                <div className={"mobileSearch"}><SearchIcon/>Search</div>
            </div> : <span className={"filler"}/>}
            {language ? <div className={"Language"} onClick={this.props.toggleLanguageSelect}><LanguageIcon/></div> : <span className={"filler"}/>}
            {this.menu ? <div className={"Menu"}><MenuIcon/></div> : "" }
            {this.state.showSearchResult && false ? <SearchResult searchResult={this.state.searchResult} params={this.props.routeData.params}/>:""}
        </div>
        );
    }

    onSearchEntry(e) {
        console.log("Clearing search timeout");
        // On every keystroke, reset the countdown timer that will initiate the search
        if(this.searchTimer) {
            window.clearTimeout(this.searchTimer);
        }
        // After the timeout, actually do the search
        this.searchTimer = window.setTimeout(this.doSearch.bind(this, e.target.value), 700);
    }

    doSearch(input) {
        search(this.props.appId, input, this.props.routeData.params.language).then(function(result) {
            this.props.setSearchResult(result);
            //this.setState({searchResult: result ? result : [], showSearchResult: (result && result.length > 0)})
            //console.log("Setting results: ", result, (result && result.length > 0));
        }.bind(this));
    }

    onChange(e) {
        let value = e.target.value;
        console.log("onChange value", value);
        if(value === "") {
            this.props.setSearchResult(null);
        } else {
            if(this.searchTimer) {
                window.clearTimeout(this.searchTimer);
            }
            // After the timeout, actually do the search
            this.searchTimer = window.setTimeout(this.doSearch.bind(this, e.target.value), 700);
        }
    }
}


export default Header;
