import React, {Component} from 'react';
import './VideoPlayer.scss';
import {IntlProvider} from "react-intl";
import {Util} from "../../providers/Utils";

const TAG = "VideoPlayer";
class ImagePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        console.log("Videoplayer did mount: ", this.props.video);
    }

    play() {
        /*ReactGA.event({
            category: 'Video',
            action: 'VideoPlay'
        });*/
    }
    stop() {
        /*ReactGA.event({
            category: 'Video',
            action: 'VideoStopped'
        });*/
    }
    pause() {
        /*ReactGA.event({
            category: 'Video',
            action: 'VideoPaused'
        });*/
    }

    render() {
        const videoUrl = Util.getMediaUrl(this.props.video) || "";
        const posterUrl = Util.getMediaUrl(this.props.videoPoster) || "";
        console.log(TAG, "VideoURL: ", videoUrl);
        if(videoUrl) {
            return (
                <div className={"VideoPlayer"}>
                    <video src={videoUrl} poster={posterUrl} controls onPlay={this.play.bind(this)} onPause={this.pause.bind(this)} onEnded={this.stop.bind(this)}/>
                </div>

            );
        } else {
            return <></>
        }

    }

}
/*
<div className="imagePlayer">
					<div className="scrollthingi">
						<div className="images">
							{imageNodes}
						</div>
					</div>
					<div className={dotnodeclass}>
						{dotnodes}
					</div>
					{lefticon}
					{righticon}
					{caption}
					<div className="fullscreen_button" onClick={this.openFullscreen}>
						<Icon name={fullscreen}/>
					</div>

				</div>
 */


export default ImagePlayer;
